@import "src/styles/breakpoints";

.contact-us-block {
  height: 100%;
  overflow-y: hidden;
  max-width: 592px;

  @include for-phone {
    margin:0 auto;
  }

  &__title {
    font-size: 40px;
    line-height: 45px;
    letter-spacing: -0.05em;
    margin-bottom:35px;

    @include for-1024 {
      margin-bottom: 20px;
    }
  }

  &__form {
    margin-top:70px;
  }

  &__footer {
    margin-top: 42px;

    @include for-phone {
      margin-top:32px;
      padding-bottom: 33px;
      bottom: auto;
    }

    .grey {
      color: #878787;
      margin: 0 10px;

      @include for-839 {
        margin: 0 10px;
      }
    }

    &-contacts {
      color: #0E0D0D;
      text-transform: uppercase;
      font-size: 16px;

      @include for-839 {
        font-size: 10px;
        flex-direction: row;
      }
    }

    &-note {
      font-family: RegularFont, sans-serif;
      font-size: 16px;
      line-height: 25px;
      margin-top:24px;
      color: #878787;

      @include for-840 {
        font-size: 14px;
      }
    }
  }
}
