@import "src/styles/breakpoints";

.header{
  position: fixed;
  top:0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 120px;
  width: 100%;
  z-index: 1;

  @include for-1024 {
    justify-content: space-between;
    background-color: rgba(0,0,0,0);
    min-height: 120px;
  }

  @include for-840 {
    flex-direction: row;
    z-index: 2;
    justify-content: space-around;
    transition: background-color 300ms ease;

    &.white {
      background-color: #FFF;
      transition: background-color 300ms ease;
    }

    h2 {
      line-height: initial;
    }
  }

  @include for-839 {
    background-color: #0e0d0d;
    min-height: 0px !important;
  }

  .left-column {
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 169px;

    @include for-1024 {
    }

    @include for-840 {
      width: 168px;
      margin-left: 0px;
    }

    @include for-839 {
      justify-content: flex-start;
      width: 16px;
      margin-left: 16px;
      flex: 0 1;
    }

    .spark{
      cursor: pointer;
      line-height: 30px;
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      justify-content: flex-end;

      @include for-1024 {
        padding-left: 0;
        justify-content: flex-start;
        margin-left: 48px;
      }
    }
  }

  .center-column{
    max-width:820px;
    width: 100%;
    min-width: 400px;
    height: 50px;
    margin:0 30px;
    flex: 1;
    display: grid;
    align-items: center;

    @include for-768 {
      width: 100%;
      min-width: 80px;
      margin:0;
    }
  }

  .right-column{
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 169px;

    @include for-1340 {
      justify-content: flex-start;
    }

    @include for-839 {
      justify-content: flex-end;
      width: 18px;
      margin-right: 16px;
      flex: 0 1;
    }
  }
}
