@import "src/styles/footer.scss";
@import "src/styles/horizontalSlide.scss";
@import "src/styles/breakpoints.scss";
@import "src/styles/mixins.scss";
@import "src/styles/keyframes.scss";
@import "src/styles/popup.scss";
@import "src/styles/layout.scss";
@import "src/styles/tabs.scss";

@font-face {
	font-family: RegularFont;
	src: url(./assets/fonts/Inter-Regular.ttf);
}
@font-face {
	font-family: BoldFont;
	src: url(./assets/fonts/Inter-Bold.ttf);
}
@font-face {
	font-family: LightFont;
	src: url(./assets/fonts/Inter-Light.ttf);
}

html, #root, .App{
  height:100%;
}

#root, html, body{
  overscroll-behavior: none;
  font-size: 16px;

  @include for-375 {
    font-size: 15px;
  }

  @include for-320 {
    font-size: 12px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: BoldFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
  font-feature-settings: 'liga' off;
  padding-bottom: env(safe-area-inset-bottom);
}

.fp-scroller{
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

@include headings {
  cursor: default;

  &.white{
    color: #fff;
    &.filled{
      color: #fff;

      &:hover{
        color: #0E0D0D;
        text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
      }
    }

    &.empty{
      color: #0E0D0D;
      text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;

      &:hover{
        color: #fff;
        text-shadow: none;
      }
    }
  }

  .black{
    color: #000;
  }

  &.red{
    color: #F26D6B;
    &.filled{
      color: #F26D6B;

      &:hover{
        text-shadow: 2px 0 0 #F26D6B, -2px 0 0 #F26D6B, 0 2px 0 #F26D6B, 0 -2px 0 #F26D6B, 1px 1px #F26D6B, -1px -1px 0 #F26D6B, 1px -1px 0 #F26D6B, -1px 1px 0 #F26D6B;
        color: #0E0D0D;
      }
    }

    &.empty{
      text-shadow: 2px 0 0 #F26D6B, -2px 0 0 #F26D6B, 0 2px 0 #F26D6B, 0 -2px 0 #F26D6B, 1px 1px #F26D6B, -1px -1px 0 #F26D6B, 1px -1px 0 #F26D6B, -1px 1px 0 #F26D6B;
      color: #0E0D0D;

      &:hover{
        color: #F26D6B;
        text-shadow: none;
      }
    }
  }
}

h1 {
  font-size: 6em;
  letter-spacing: -0.1em;
  font-weight: bold;
  font-family: BoldFont, sans-serif;
  margin: 0;

  @include for-1024 {
    font-size: 5em;
  }

  @include for-768 {
    font-size: 3em;
  }

  @include for-375 {
    font-size: 2.3em;
  }

  @include for-320 {
    font-size: 2.8em;
  }
}

h2 {
  font-family: LightFont, sans-serif;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: normal;

  @include for-375 {
    font-size: 1em;
  }

  @include for-320 {
    font-size: 1.2em;
  }
}

h3 {
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -0.05em;
  font-weight: bold;
  font-family: BoldFont, sans-serif;
  margin: 0;

  @include for-768 {
    font-size: 2em;
    line-height: 50px;
  }

  @include for-375 {
    font-size: 1.4em;
  }
}

h4 {
  font-size: 80px;
  line-height: 110px;
  font-family: BoldFont, sans-serif;
  letter-spacing: -0.05em;
  margin-top: 20px;
  margin-bottom: 20px;

  @include for-768 {
    font-size: 2.5em;
    line-height: initial;
  }

  @include for-375 {
    font-size: 1.7em;
    line-height: initial;
  }

  @include for-320 {
    font-size: 2em;
    line-height: initial;
  }
}

.content{
  position: relative;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.fp-scrollable {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.fp-scrollable, .fp-scroller {
  overflow: auto !important;
}

input {
  margin:15px 0;
  width:100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #FFF;
  color: #FFFFFF;
  opacity: 0.3;
  box-sizing: border-box;
  font-size: 20px;
  font-family: RegularFont, sans-serif;
  padding: 10px 0px;
  position: relative;
  top: 50%;

  &:focus {
    outline: none;
    opacity: 1;
  }

  &:not([value=""]) {
    outline: none;
    opacity: 1;
  }
}

button {
  height:60px;
  background: #F26D6B;
  outline: none;
  border:0;
  color:#FFF;
  font-family: BoldFont, sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;

  &:hover {
    background-color: #F26D6BEE;
  }

  &:active {
    box-shadow: inset 0px 0px 6px #a54d4b;
  }
}

.static {
  position: static;
  overflow: hidden;
}

@media only screen and (max-width: 1024px) {
  .menu-wrapper {
    .menu-list {
      .menu-item {
        font-size: 16px !important;
        letter-spacing: -0.05em !important;
        font-feature-settings: 'liga' off;
      }
    }
  }

}

@media only screen and (max-width: 768px) {

  @include headings {
    cursor: default;

    &.white{
      color: #fff;
      &.filled{
        color: #fff;

        &:hover{
          color: #fff;
          text-shadow: none;
        }
      }

      &.empty{
        color: #0E0D0D;
        text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;

        &:hover{
          color: #0E0D0D;
          text-shadow: none;
        }
      }
    }

    .black{
      color: #000;
    }

    &.red{
      color: #F26D6B;
      &.filled{
        color: #F26D6B;

        &:hover{
          text-shadow: none;
          color: #F26D6B;
        }
      }

      &.empty{
        text-shadow: 2px 0 0 #F26D6B, -2px 0 0 #F26D6B, 0 2px 0 #F26D6B, 0 -2px 0 #F26D6B, 1px 1px #F26D6B, -1px -1px 0 #F26D6B, 1px -1px 0 #F26D6B, -1px 1px 0 #F26D6B;
        color: #0E0D0D;

        &:hover{
          text-shadow: 2px 0 0 #F26D6B, -2px 0 0 #F26D6B, 0 2px 0 #F26D6B, 0 -2px 0 #F26D6B, 1px 1px #F26D6B, -1px -1px 0 #F26D6B, 1px -1px 0 #F26D6B, -1px 1px 0 #F26D6B;
        color: #0E0D0D;
        }
      }
    }
  }
}

.hidden {
  height: 0;
  overflow: hidden;
}

#fp-nav {
  display:none !important;
}

.article {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include for-phone {
    min-width: 100%;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
  }

  &-icon {
    text-align: center;

    @include for-phone {
      min-width:0;
    }
  }

  &-header {
    color: #F26D6B;
    text-align: left;
    text-transform: uppercase;
    font-size: 16px;
    @include for-phone {
      margin-bottom:15px;
    }


  }

  &-text {
    font-size: 20px;
    line-height: 30px;
    font-family: RegularFont, sans-serif;
    margin-top:20px;

    @include for-1439 {
      font-size:16px;
      line-height: 25px;
    }
  }

  &-content {
    margin-top: 15px;
  }
}

.inactive {
  opacity: 0.4;
}

input, textarea {
  border-radius: 0;
  -webkit-appearance: none;
}

.full-height {
  height: fit-content;
}

.tooltip {
  position: absolute;
  bottom: -35px;

  &-content {
    min-width: 30px;
    padding: 6px 8px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 2px;
  }
}

.ant-tabs-tab {
  margin-right: 0px !important;
}