@keyframes light{
  0% {
    text-shadow: 0px 0px 0px #000, 0 0 0em #880000;
  }
  11% {
    text-shadow: 0px 0px 0px #b50000, 0 0 12px #880000;
  }

  12% {
    text-shadow: 0px 0px 0px #b50000, 0 0 12px #880000;
  }

  14%{
    text-shadow: 0px 0px 0px #000, 0 0 0em #880000;
  }

  16% {
    text-shadow: 0px 0px 0px #000, 0 0 0em #880000;
  }
  17% {
    text-shadow: 0px 0px 0px #b50000, 0 0 12px #880000;
  }

  19% {
    text-shadow: 0px 0px 0px #b50000, 0 0 12px #880000;
  }

  20%{
    text-shadow: 0px 0px 0px #000, 0 0 0em #880000;
  }

  23%{
    text-shadow: 0px 0px 0px #000, 0 0 0em #880000;
  }

  25% {
    text-shadow: 0px 0px 0px #b50000, 0 0 12px #880000;
  }

  27% {
    text-shadow: 0px 0px 0px #b50000, 0 0 12px #880000;
  }

  31%{
    text-shadow: 0px 0px 0px #000, 0 0 0em #880000;
  }

  40%{
    text-shadow: 0px 0px 0px #000, 0 0 0em #880000;
  }

  43% {
    text-shadow: 0px 0px 0px #b50000, 0 0 12px #880000;
  }

  100%{
    text-shadow: 0px 0px 0px #b50000, 0 0 12px #880000;
  }
}
