.popup{
  position: fixed;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #ffffff;
}
