@import "src/styles/breakpoints";

.form {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  color: #0E0D0D;

  &-row {
    display: flex;
    justify-content: space-between;

    @include for-980 {
      flex-direction: column;

      &>div.ant-col {
        width:100%;
      }
    }

    @include for-phone {
      flex-direction: column;

      &>div.ant-col {
        width:100%;
      }
    }

    .ant-col-11, .ant-col-12 {
      width: 50%;
    }

    .ant-col-11 {
      margin-right: 15px;
    }
  }

  &-input {
    border-bottom: 1px solid #0E0D0D;
    font-family: RegularFont, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #0E0D0D;
    background: #FFF;

    &:focus {

    }


  }

  &-textarea {
    textarea {
      font-family: RegularFont, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 25px;
      width:100%;
      border:none;
      border-bottom: 1px solid #0E0D0D;
      padding:10px 0;
      opacity:0.3;
      margin-bottom: 10px;
      margin-top:15px;


      &:focus {
        outline: none;
        opacity:1;
      }

      &:not(:placeholder-shown) {
        outline: none;
        opacity: 1;
      }
    }
  }

  &-uploader {
    margin-top:40px;

    .hide {
      display: none;
    }

    .upload-label {
      font-size: 20px;
      line-height: 30px;
      color: #878787;
      margin-left:15px;

      @include for-840 {
        font-size: 14px;
      }

      @include for-980 {
        font-size: 16px;
      }

      @include for-phone {
        margin-left:30px;
      }

      @include for-320 {
        margin-left:27px;
      }
    }

    .ant-upload-list-item-info {

      .ant-upload-list-item-name {
        font-family: RegularFont, sans-serif;
        font-size: 16px;
        line-height: 25px;
        color: #0E0D0D;
      }
      .ant-upload-list-item-card-actions-btn {
        height:auto;
        background: transparent;
        color: #0E0D0D;

        & > span.anticon {
          color: #CBCBCB;
        }
      }

      .ant-upload-text-icon {
        display:none;
      }
    }

    .uploadList {
      margin-bottom: 6px;
      display: grid;
      grid-template-columns: 26px max-content 16px;
      font-family: RegularFont, sans-serif;
      font-size: 16px;
      align-items: center;

      &__filePic {
        margin-top: 6px;
      }

      &__deleteButton {
        margin: 6px 0px 0px 8px;

        img {
          opacity: 0.5;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  &-upload-btn {
    padding:0;
    background-color: transparent;
    color: #F26D6B;
    font-family: RegularFont, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    cursor: pointer !important;
    display: flex;
    align-items: center;

    @include for-980 {
      font-size: 16px;
    }

    @include for-840 {
      font-size: 14px;
    }

    @include for-phone {
      text-align: left;
      flex-wrap: wrap;
    }

    & > span {
      cursor: pointer !important;
    }

    .anticon {
      margin-right:13px;
    }

    &:hover, :active {
      background-color: transparent;
      box-shadow: none;
    }
  }

  &-submit-wrapper {
    margin-top:45px;
  }

  &-submit-btn {
    font-family: BoldFont, sans-serif;
    width:100%;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
  }

}
