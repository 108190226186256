@import "src/styles/breakpoints";

.social-links{
  display: flex;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
  width:65px;
  @include for-1340 {
    margin-right:36px;
  }

  @include for-phone {
    display: none;
  }

  .link-instagram {
    display: flex;
    align-items: center;
  }

  a {
    display: flex;
  }

  svg{

  }
}
