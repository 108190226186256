.scroll-footer {
  position:absolute;
  bottom:0;
  width:100%;
  margin-bottom: 36px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.25em;
}
