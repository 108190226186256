@import "src/styles/breakpoints";

.menu-wrapper{

  @include for-phone {
    display: block;
  }

  .menu-list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    padding: 0;

    @include for-1340 {
      justify-content: center;
      margin-top: 17px
    }

    @include for-320 {
      margin-top: 15px;
    }

    .menu-item{
      font-family: BoldFont, sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      color: #878787;
      text-align: center;
      cursor: pointer;
      letter-spacing: 0.1em;

      h2 {
        cursor: pointer;
      }

      @include for-1340 {
        display: none;
      }

      a {
        white-space: nowrap;
      }

      &.active{
        a {
          color: #F26D6B;
        }

        @include for-1340 {
          display: block;
        }
      }

      &:hover {
        a {
          color: #F26D6B;
        }
      }
    }
  }
}
