@import "src/styles/breakpoints";

.section {
  @include for-839 {
    justify-content: center;
  }
}

.whatwedo {
  display: flex;
  flex-direction: column;
  color:#000;
  height: 100%;
  background-color: #FFF;
  align-items: center;

  @include for-839 {
    flex: 1
  }

  .domains {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-grow: 1;
    height: 50vh;
    box-sizing: border-box;
    width: 1336px;

    @include for-1919 {
      width: 1336px;
    }

    @include for-1439 {
      width: 1024px;
    }

    @include for-1023 {
      padding-bottom: 0;
      width: 750px;
    }

    @include for-839 {
      width: 100%;
      height: auto;
      padding: 32px 16px 24px;
      justify-content: flex-start;
    }

    .mobile {
      a {
        width: 100%;
      }
    }

    & > div {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      @include for-839 {
        margin-bottom: 16px;
        align-items: flex-start;
      }
    }

    &-item {
      font-family: RegularFont, sans-serif;
      cursor: pointer;
      outline: none;
      display: grid;
      grid-template-columns: 112px auto;
      outline: none;

      @include for-839 {
        margin-bottom: 24px;
        grid-template-columns: 96px auto;
      }

      &--img {
        border-radius: calc(100% / 2);
        width: 112px;
        height: 112px;
        overflow: hidden;

        @include for-839 {
          width: 96px;
          height: 96px;
        }

        img {
          width: 100%;
        }
      }

      &-textContainer {
        margin-left: 32px;
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: left;
        justify-content: center;

        @include for-1440 {
          width: 134px;
        }

        @include for-1023 {
          width: auto;
        }

        @include for-320 {
          margin-left: 18px;
        }

        &--text {
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 30px;
          font-feature-settings: 'liga' off;
        }

        &--description {
          font-feature-settings: 'liga' off;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #F26D6B;
        }
      }
    }

    & > .rec-carousel-wrapper {
      position: relative;

      & > .rec-carousel {
        .rec-slider-container {
          margin: 0px;

          @include for-1439 {
            margin: 0px 48px;
          }

          @include for-1023 {
            margin: 0px 0px;
            display: block;
          }



          .rec-slider {

          }


          .rec-swipable {
            align-items: flex-start;

            .rec-item-wrapper {
                justify-content: flex-start;
            }
          }
        }
      }

      & > .rec-pagination{
        position: absolute;
        bottom: 0px;
        margin-bottom: 34px;

        & > .rec-dot {
          background-color: #404040;
          width:16px;
          height: 16px;
          padding:0;
          box-shadow:none;

          &_active {
            background-color: #F26D6B;
            box-shadow:none;
          }
        }
      }
    }
  }

  .feedbacks {
    color: #FFF;
    background: #1D1B1B;
    display: flex;
    height: 50vh;
    justify-content: space-evenly;
    box-sizing: border-box;
    width: 100%;


    @include for-839 {
      height: 380px;
      padding: 32px 0px;
    }

    & > div {
      margin-top: 92px;
      max-width: 1336px;

      @include for-1439 {
        width: 1024px;
      }

      @include for-1023 {
        width: 750px;
      }

      @include for-839 {
        margin-top: 0px;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      max-width:432px;
      font-family: RegularFont, sans-serif;
      margin: 0;
      outline: none;
      justify-content: center ;

      @include for-1439 {
        margin: 0px 25px;
      }

      @include for-1023 {
        margin: 0px;
      }

      @include for-839 {
        margin: 0 16px;
      }

      &-container {
        display: grid;
        grid-template-columns: 44px auto;

        &--img {
          width: 44px;
          height: 44px;
          overflow: hidden;
          border-radius: 22px;
          
          img {
            width: 100%;
          }
        }

        &-person {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 16px;

          &--name {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            font-feature-settings: 'liga' off;

            @include for-320 {
              font-size: 16px;
              line-height: 24px;
            }
          }

          &--position {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            font-feature-settings: 'liga' off;
            color: #878787;

            @include for-phone {
              text-align: left;
            }

            @include for-320 {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }

      &--feedback {
        margin-top: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        font-feature-settings: 'liga' off;
        text-align: left;

        @include for-320 {
          font-size: 14px;
          margin: 10px 0px;
          line-height: 20px;
        }
      }

      &--org {
        font-size: 16px;
        line-height: 25px;
        color: #878787;
      }
    }

    & > .rec-carousel-wrapper {
      position: relative;
      margin-bottom: 60px;

      @include for-839 {
        margin-bottom: 32px;
      }


      & > .rec-carousel {
        .rec-slider-container {
          margin: 0px;

          @include for-1439 {
            margin: 0px 23px;
          }

          @include for-1023 {
            margin: 0;
          }
        }

        .rec-swipable {
          align-items: flex-start;

          .rec-item-wrapper {
              justify-content: flex-start;
          }
        }
      }

      & > .rec-pagination{
        position: absolute;
        bottom: 0;

        @include for-phone{
          bottom: -30px;
        }

        & > .rec-dot {
          background-color: #404040;
          width:16px;
          height: 16px;
          padding:0;
          box-shadow:none;

          &_active {
            background-color: #fff;
            box-shadow:none;
          }
        }
      }
    }
  }
}
