@import '~antd/lib/message/style/index.css';

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-form-item-explain-error {
  color: #F26D6B;
  font-size: 14px;
  font-family: 'RegularFont', sans-serif;
  position: relative;
  top: -10px;
}

.ant-form-item-explain-success {
  display: none;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
   color: #F26D6B !important;
 }

.ant-tabs-ink-bar {
  background: #F26D6B !important;
}

.ant-tabs-tab:hover {
  color: #F26D6B !important;
}

.ant-timeline-item-tail {
  border-left: 2px solid #404040 !important;
  border-radius: 2px;
  left: 5px !important;
  top: 20px !important;
}

.ant-timeline-item-head {
  background-color: transparent !important;
  border: 2px solid #F26D6B !important;
  width: 8px !important;
  height: 8px !important;
}

.ant-timeline-item {
  color: #fff !important;
  margin-bottom: 20px !important;
  padding-bottom: 0 !important;
}

.ant-tabs-nav {
  margin-bottom: 15px !important;
}

.hide {
  display: none;
}