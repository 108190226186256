.layout{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.black{
    @include for-839 {
      background-color: #0E0D0D;
    }
  }

  &.white{
    @include for-839 {
      background-color: #FFFFFF;
    }
  }

  @keyframes toWhite {
    0% {background: #0E0D0D;}
    100% {background: #fff;}
  }

  @-webkit-keyframes toWhite {
    0% {background: #0e0d0d;}
    100% {background: #fff;}
  }

  @-moz-keyframes toWhite {
    0% {background: #0e0d0d;}
    100% {background: #fff;}
  }

  @keyframes toBlack {
    0% {background: #fff;}
    100% {background: #0E0D0D;}
  }

  @-webkit-keyframes toBlack {
    0% {background: #fff;}
    100% {background: #0E0D0D;}
  }

  @-moz-keyframes toBlack {
    0% {background: #fff;}
    100% {background: #0E0D0D;}
  }

}
