@import "src/styles/breakpoints";

.mobile-menu {

  .link-instagram {
    margin-top:72px;
  }

  .mobile-menu-icon{
    display: block;
    cursor: pointer;
  }

  .close-icon{
    padding: 20px 0 2px 0;
    text-align: end;
    margin-right:30px;
  }

  .menu-list{
    list-style: none;
    padding: 0;
    text-align: center;
    margin: 0px;

    .menu-item{
      margin: 0px;
      text-transform: uppercase;
      font-size: 18px;
      padding: 16px 0;
      letter-spacing: 0.1em;
      color: #878787;
    }
  }

  .btn-container {
    width:100%;
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }

  .contact-us-btn {
    width:220px;
    height: 60px;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;

    span {
      cursor: pointer;
    }
  }
}
