@import "src/styles/breakpoints";

.howwework {
  font-size: 1.2em;
  line-height: 1.5em;
  font-family: BoldFont, sans-serif;

  .fp-tableCell{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 924px;

    @include for-1439 {
      width: 924px;
    }

    @include for-1024 {
      flex-direction: column;
      width: 100%;
    }

    @include for-phone {
      background-color: #fff;
      min-width: 100%;
    }

    .definition-container {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 80px;

      @include for-1024 {
        flex-direction: column;
      }

      @include for-1023 {
        width: 750px;
      }

      @include for-839 {
        margin-top: 35px;
        width: 100%;
      }

    }

    .definition{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-bottom: 48px;
      max-width: 900px;

      &:first-child {
        padding-bottom: 60px;
      }

      @include for-1024 {
        max-width: 100%;
        padding-left: 48px;
        padding-right: 48px;
      }

      @include for-1023 {
        padding-left: 0px;
        padding-right: 0px;
      }

      @include for-840 {
        max-width: 100%;
      }

      @include for-839 {
        flex-direction: column;
        padding-right: 16px;
        padding-left: 16px;
        align-items: flex-start;
      }

      .icon{
        margin-left: 4px;


        @include for-phone {
          min-width:0;
        }
      }

      .list{
        display: flex;
        flex-direction: column;
        margin-top: 11px;

        .termin {
          color: #F26D6B;
          text-align: left;
          text-transform: uppercase;
          font-size: 16px;
          letter-spacing: -0.05em;

          @include for-phone {
            margin-bottom:15px;
          }

          @include for-320 {
            margin-bottom:5px;
          }
        }

        .text {
          font-size: 20px;
          line-height: 30px;
          font-family: RegularFont, sans-serif;
          text-align: left;
          margin-top: 14px;

          @include for-839 {
            font-size: 16px;
            line-height: 24px;
            margin-top: 10px;
          }


          &__to-timeline {
            font-size: 16px;
            line-height: 100%;
            font-family: BoldFont, sans-serif;
            text-transform: uppercase;
            margin-top: 20px;
            display: flex;
            align-items: center;
            height: 50px;
            padding: 0 24px;
            letter-spacing: -0.05em;

            span {
              margin-left:12px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  @include for-phone {
    & > div {
      line-height: 1.4em;
      max-width: 100%;

      &.vision{
        .icon{
          top: -80px;
          left: 273px;
        }
      }

      &.mission{
        .icon{
          top: -98px;
          left: -226px;
        }
      }
    }
  }
}

@media only screen and (max-width: 414px){
  .mission-vision{
    .fp-tableCell{
      .content{
        .definition{
          .list{
            .termin::after{
              display: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 320px){
  .mission-vision{
    .fp-tableCell{
      .content{
        padding-top: 75px;

        .definition{
          flex-direction: column;

          .icon{

          }
        }
      }
    }
  }
}
