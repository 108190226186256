@import "../../styles/breakpoints";

.time-and-materials {

  .pink {
    color: #F26D6B;
  }

  &-info {

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      max-width:490px;

      @include for-1440 {
        max-width:464px;
      }

      @include for-839 {
        min-width: 288px;
      }
    }

    &__title {
      font-size: 40px;
      line-height: 45px;
      letter-spacing: -0.05em;
      margin-bottom:32px;
      font-weight: bold;
      font-family: BoldFont, sans-serif;
      margin-top:0;
      text-transform: none;
    }

    &__list {
      margin-bottom: 40px;

      @include for-839 {
        margin-bottom: 23px;
      }

      @include for-320 {
        margin-left: 0;
      }

      .ant-tabs-nav {
        overflow: hidden;

        @include for-1024 {
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.25em;
        }
      }

      .title {
        font-size: 16px;
        line-height: 100%;
        text-transform: uppercase;
        color: #878787;
        margin-bottom: 28px;
      }

      .list {
        list-style-image: url("../../assets/images/svgs/list-icon.svg");
        padding:0;

        @include for-839 {
          padding-left: 20px;
        }

        &-item {
          font-size: 16px;
          line-height: 25px;
          font-family: RegularFont, sans-serif;
          margin-bottom: 17px;
        }
      }
    }
  }

  &-tabs {
    overflow: visible;

    .ant-tabs-nav {
      width: fit-content;
      color: #878787;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.25em;
      text-transform: uppercase;

      &::before {
        border-bottom: 1px solid #404040;
      }

      .ant-tabs-nav-list {
        .ant-tabs-tab {
          &-active {
            color: #F26D6B !important;
          }
        }
      }
    }

  }

  &-contacts {
    &__title {
      font-size: 40px;
      line-height: 45px;
      letter-spacing: -0.05em;
    }
  }

  .ant-timeline {
    margin-top: 35px !important;

    @include for-more-1440 {
      margin-left: -36px !important;
    }
  }

  .custom-collapse-panel {
    &.last {
      .ant-collapse-header {
        &[aria-expanded="false"] {
          margin-bottom: 0;
        }
      }
    }
  }
}

.slide-up {
  pointer-events: all !important;
}

.slide-up-leave {
  display: none;
}

.timeline-item {

  &__title{
    font-family: BoldFont, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 100%;
    text-transform: uppercase;
    color: #F26D6B;
    padding-top: 1px;

    @include for-839 {
      font-size: 14px;
    }

    &-label {
      background: #F26D6B;
      border-radius: 2px;
      margin-left: 16px;
      color: white;
      font-family: RegularFont, sans-serif;
      line-height: 25px;
      padding: 6px 8px;
      text-transform: none;
      font-style: normal;
      font-weight: normal;

      @include for-839 {
        font-size: 12px;
        margin-left: 8px;
      }
    }
  }

  &__text {
    font-family: RegularFont, sans-serif;
    font-size: 16px;
    line-height: 25px;

    @include for-839 {
      font-size: 14px;
    }
  }

  &__list {
    padding:10px 0 10px 15px;

    li {
      padding:5px 0;

      &::marker {
        //color: #F26D6B;
      }
    }
  }

  &__footer {
    font-family: RegularFont, sans-serif;
    font-size: 16px;
    line-height: 25px;
    color: #F26D6B;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;

    &--text {
      margin-left: 8px;
      margin-top: -8px;
    }

    
  }
}

.materials {

  @include for-839 {
    margin-left: 0px;
  }

  &-item {
    margin: 35px 0;

    &-title {
      &--text {
        font-family: BoldFont, sans-serif;
        font-size: 16px;
        line-height: 100%;
        text-transform: uppercase;
        color: #F26D6B;
        margin-top: 15px;
      }
    }

    &-text {
      font-family: RegularFont, sans-serif;
      font-size: 16px;
      line-height: 25px;
      color: #FFFFFF;
      margin-top: 20px;

      red {
        color: #F26D6B;
      }
    }
  }
}

.ant-timeline-item-content {
  margin: 0 0 0 36px;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 32px;
}