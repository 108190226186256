@import "src/styles/breakpoints";

.vacancy {

  .pink {
    color: #F26D6B;
  }

  &-info {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;

    &-content {
      padding-right: 126px;
      padding-left:48px;

      @include for-1439 {
        padding-right: 32px;
        padding-left:48px;
      }

      @include for-839 {
        padding-right: 16px;
        padding-left:16px;
      }
    }

    &__title {
      font-size: 40px;
      line-height: 45px;
      letter-spacing: -0.05em;
      margin-bottom:32px;
    }

    &__list {
      margin-bottom: 32px;

      ul {
        @include for-1439 {
          margin-left: 20px;
        }

        @include for-phone {
          margin-left: 20px;
        }
      }

      &.gray {
        max-width: 100%;
        width: 100%;
        align-self: flex-start;
        background: #1D1B1B;
        padding-top: 32px;
        padding-bottom: 32px;
      }

      &--full {
        align-self: flex-end;
        padding-right: 126px;
        padding-left: 48px;
        max-width: 592px;
        width: 100%;

        @include for-1919 {
          max-width: 432px;
          width: auto;
        }

        @include for-1439 {
          padding-right: 32px;
          padding-left:48px;
        }

        @include for-839 {
          padding-right: 16px;
          padding-left:16px;
          align-self: flex-start;
        }
      }

      .title {
        font-size: 16px;
        line-height: 100%;
        text-transform: uppercase;
        color: #878787;
        margin-bottom: 16px;
      }

      .list {
        list-style-image: url("../../assets/images/svgs/list-icon.svg");
        padding:0;

        &-item {
          font-size: 16px;
          line-height: 25px;
          font-family: RegularFont, sans-serif;
          margin-bottom: 10px;
        }
      }
    }

    .positions {
      margin-top: 4px;
      padding-right: 126px;
      padding-left: 48px;
      max-width: 592px;
      width:100%;

      @include for-1919 {
        max-width: 432px;
      }

      @include for-1439 {
        padding-right: 32px;
        padding-left:48px;
        align-self: flex-end;
      }

      @include for-1023 {
        align-self: flex-start;
      }

      @include for-839 {
        padding-right: 16px;
        padding-left:16px;
      }

      .article-content {
        margin-top: 24px;

        .article-text {
          margin-top: 16px;

          ul {
            margin-top: 16px;
          }
        }
      }

      &-header {
        font-size: 40px;
        line-height: 45px;
        letter-spacing: -0.05em;
        margin-bottom:30px;
      }

      &-list {
        list-style: none;
        padding: 0;
        font-size: 20px;
        line-height: 30px;
        font-family: RegularFont, sans-serif;

        @include for-839 {
          font-size: 14px;
          line-height: 21px;
        }

        & > li {
          margin-bottom:15px;
          cursor:pointer;
          text-decoration: none;

          &:hover {
            text-decoration: underline;

            @include for-839 {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  &-contacts {
    &__title {
      font-size: 40px;
      line-height: 45px;
      letter-spacing: -0.05em;
    }
  }
}
