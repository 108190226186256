@import "src/styles/breakpoints";

.contact-us {
  position: relative;
  min-height: 100%;

  @include for-839 {
    display: block;
  }

  h3 {
    font-size: 28px;
    line-height: 45px;
    letter-spacing: 0;
    margin-bottom: 0px;
    font-weight: normal;
    font-family: RegularFont, sans-serif;
    text-align: left;

    @include for-1024 {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__position {
    font-family: RegularFont, sans-serif;
    font-size: 16px;
    line-height: 100%;
    text-transform: uppercase;
    color: #878787;
    margin-bottom: 14px;
    text-align: left;
  }

  &__title {
    font-size: 40px;
    line-height: 45px;
    letter-spacing: -0.05em;
    margin-bottom:32px;
    text-align: left;
    font-weight: bold;
  }

  &__text {
    font-family: RegularFont, sans-serif;
    font-size: 16px;
    line-height: 24px;

    @include for-839 {
      font-size: 14px;
      line-height: 21px;
    }

  }

  &__person {
    display: flex;
    margin-bottom: 32px;

    &--details {
      margin-left:15px;
    }
  }

  &__pic {
    text-align: left;

    @include for-phone {
      text-align: center;
    }

    img {
      width: 60px;
      height: 60px;
      border-radius: 100px;
      border: 2px solid #F26D6B;
    }
  }

  &__footer {
    font-family: RegularFont, sans-serif;
    font-size: 16px;
    line-height: 100%;
    text-transform: uppercase;
    color: #878787;
    margin-top: 32px;
    text-align: left;
    margin-bottom: 23px;
  }
}
