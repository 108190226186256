@import "src/styles/breakpoints";

.home {
    font-family: "RegularFont";
    background-color: #0E0D0D;

    @include for-840 {
        justify-content: center;
    }

    div {
        font-style: normal;
        font-weight: normal;
        font-size: 70px;
        line-height: 105px;
        text-align: center;
        letter-spacing: -0.03em;
        font-feature-settings: 'liga' off;

        @include for-768 {
            font-size: 30px;
            line-height: 45px;
        }

        .white {
            color: #FFFFFF;

            &:hover {
                color: #0E0D0D;
                text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
            }
        }

        .red {
            color: #F26D6B;

            &:hover {
                text-shadow: 2px 0 0 #f26d6b, -2px 0 0 #f26d6b, 0 2px 0 #f26d6b, 0 -2px 0 #f26d6b, 1px 1px #f26d6b, -1px -1px 0 #f26d6b, 1px -1px 0 #f26d6b, -1px 1px 0 #f26d6b;
                color: #0E0D0D;
            }
        }
    }
}
