@import "src/styles/breakpoints";

.horizontal-slide {
  display: flex;
  text-align: start;
  width: 100%;
  min-height: 100vh;
  position: relative;


  @include for-839 {
    flex-direction: column;
  }

  .back {
    position: absolute;
    top: 52px;
    left: 48px;
    cursor: pointer;
    z-index: 5;

    @include for-839 {
      top: 24px;
      left:16px;
      color: #F26D6B;
    }
  }

  &__left-side {
    flex-basis: 50%;
    flex-grow:1;
    background-color: #0E0D0D;
    color:#FFF;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    box-sizing: border-box;
    padding:96px 166px 25px 48px;

    @include for-1919 {
      padding:96px 126px 25px 48px;
    }

    @include for-1024 {
      padding:96px 32px 25px 48px;
      align-items: flex-start;
    }

    @include for-839 {
      padding:64px 16px 25px 16px;
    }

    &>div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      max-width: 592px;

      @include for-1919 {
        max-width: 432px;
      }

      @include for-1023 {
        max-width: 340px;
      }
    }
  }

  &__right-side {
    flex-basis: 50%;
    flex-grow:1;
    background-color: #FFF;
    color: #0E0D0D;
    box-sizing: border-box;
    padding: 96px 162px 25px 126px;

    @include for-1340 {
      padding: 96px 60px 25px 60px;
    }

    @include for-1024 {
      padding: 96px 48px 25px;
    }

    @include for-phone {
      padding: 50px 40px;
    }

    @include for-768 {
      padding: 50px 16px;
    }
  }
}
