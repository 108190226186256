@import "./breakpoints";

.tabs {
  overflow: visible;

  ul {
    @include for-320 {
      padding: 0 20px;
    }
  }

  .ant-tabs-nav {
    color: #878787;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    width: fit-content;

    @include for-768 {
      margin-left: 0;
    }

    @include for-320 {
      margin-left: 20px;
    }

    &::before {
      border-bottom: 1px solid #404040 !important;
      width:272px;
      @include for-1023 {
        width:253px;
      }
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        padding: 0 0 16px 0 !important;
        font-size:12px !important;
        line-height: 18px !important;
        letter-spacing: 0.25em !important;

        &-active {
          color: #F26D6B !important;
        }
      }
    }
  }
}

.ant-tabs-tab + .ant-tabs-tab {
  @include for-1023 {
    margin-left: 14px !important;
  }
}
