@import "src/styles/breakpoints";

.contacts {
  display: flex;
  flex-direction: column;
  width: 100%;

  align-items: center;
  justify-content: flex-end;
  height: 100%;

  @include for-839 {
    background-color: #FFF;
  }

  .info-block {
    width: 100%;
    max-width: 924px;
    display: flex;
    flex-direction: row;
    flex-basis: 35%;
    padding-top: 100px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 24px;

      @include for-1024 {
        max-width: none;
      }

    @include for-839 {
      flex-direction: column;
      padding: 0px 16px;
      justify-content: center;
      margin-top: 32px;
      margin-bottom: 0px;
    }

    .address {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      line-height: 16px;
      color: #878787;
      text-align: left;
      width: 192px;
      margin: 0px 0px 40px 0px;

      @include for-1024 {
        margin-left: 48px;
      }

      @include for-840 {

      }

      @include for-839 {
        margin: 40px 0;
        text-align: center;
      }

      @include for-320 {
        margin: 32px 0;
      }

      .street {
        color: #404040;
        margin-top: 30px;
        text-transform: uppercase;

        @include for-840 {

        }
      }

      .floor {
        font-family: RegularFont, sans-serif;
        line-height: 25px;
      }
    }

    .main-info {
      width: 432px;

      @include for-1024 {
        width: auto;
      }

      .izhevsk {
        font-family: RegularFont, sans-serif;
        letter-spacing: -0.03em;
        font-size: 70px;
        line-height: 110px;

        @include for-839 {
          font-size: 40px;
          line-height: 45px;
          margin-bottom: 20px;
        }
      }

      .email {
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #404040;
        text-transform: uppercase;
        margin-top: 15px;
      }

      .phone {
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #404040;
        text-transform: uppercase;
        margin-top: 15px;
      }
    }

    .work-time {
      width: 192px;
      text-align: right;
      margin: 0px 0px 40px 0px;

      @include for-1024 {
        margin-right: 48px;
      }

      @include for-840 {

      }

      @include for-839 {
        margin: 0;
        width: 100%;
      }

      &--btn {
        width: 170px;
        height: 50px;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        cursor: pointer;

        @include for-839 {
          width: 100%;
          margin-bottom: 35px;
        }

        span {
          cursor: pointer;
        }
      }
    }
  }

  .map {
    position: relative;
    flex-basis: 55%;
    width: 100%;

    @include for-1024 {
      flex-basis: 48%;
    }

    @include for-840 {
      flex-basis: 40%;
    }

    .map-image-small {
      display: none;
    }


    .spark-icon {
      position: absolute;
      top: 250px;
      left: 540px;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 47px 45px 66px;
    text-align: start;

    @include for-1024 {
      padding-left: 48px;
      padding-right: 48px;
    }

    @include for-840 {

    }

    @include for-839 {
      flex-direction: column;
      padding: 30px 16px 0px;
      align-items: flex-start;
      height: 100%;
    }

    & > div {

      @include for-839 {
        margin-bottom: 32px;
      }

    }

    &__block {
      @include for-desktop {
        display: flex;
        align-items: center;
      }
      @include for-839 {
        display: grid;
        grid-template-columns: 32px auto;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    &--img {
      margin-right: 15px;
      position: relative;
      top: 6px;
      margin-bottom: 10px;

      @include for-839 {
        top: 0px;
        margin-bottom: 0px;
      }


    }

    &--red-text {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.25em;
      font-weight: bold;
      color: #F26D6B;
    }

    &--grey-text {
      font-size: 16px;
      line-height: 25px;
      color: #878787;
      font-family: RegularFont, sans-serif;
      margin-left: 31px;

      @include for-839 {
        font-size: 14px;
        line-height: 21px;
        margin-left: 32px;
      }

      &.ceo {
        margin-left: 35px;

        @include for-839 {
          margin-left: 32px
        }
      }
    }
  }

  a.link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 768px) {
  .map {
    align-self: end;
  }
}

@media only screen and (max-width: 414px) {
  .contacts {

    .map {
      position: relative;
      flex-basis: 50%;
      max-height: 50%;
      width: 100%;
      align-self: end;

      .map-image {
        display: none;
      }


      .map-image-small {
        display: block;
        width: 100%;
        object-fit: cover;
      }

      .spark-icon {
        top: 140px;
        left: 185px;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .contacts {
    .map {
      .spark-icon {
        top: 100px;
        left: 140px;
      }
    }

    .info-block {
      h4 {
        margin-bottom: 0;
      }

      .address {
        font-family: BoldFont;

      }
    }
  }
}
