@import "../../styles/breakpoints";

.domain {
  .pink {
    color: #F26D6B;
  }

  &-info {
    @include for-839 {
      align-items: flex-start;
      padding-bottom: 30px;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      max-width:432px;

      @include for-1023 {
        max-width: 349px;
      }
    }

    &__title {
      font-family: RegularFont, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 50px;
      letter-spacing: -0.05em;
      color: #FFF;

      margin-bottom: 32px;
    }
  }

  &-description {
    padding: 96px 0 0 0;

    @include for-839 {
      width: unset;
      padding-bottom: 0;
      padding-top: 32px;
    }

    &__title {
      font-family: BoldFont, sans-serif;
      font-size: 40px;
      line-height: 45px;
      letter-spacing: -0.05em;
      margin-bottom:32px;
      text-transform: none;
      padding-left: 60px;

      @include for-839 {
        padding-left: 16px;
      }
    }

    &__devices {
      display: flex;
      margin-bottom: 40px;
      padding-left: 63px;

      @include for-839 {
        padding-left: 16px;
      }

      .device-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right:36px;
        cursor: pointer;
        position: relative;

        .active {
          svg {
            fill: #F26D6B;
          }
        }
      }
    }

    &__img {
      width:100%;
       img {
         width: 100%;
         vertical-align: middle;
         display: block;
         -khtml-user-select: none;
         -o-user-select: none;
         -moz-user-select: none;
         -webkit-user-select: none;
         user-select: none;
       }
    }
  }
}

.domain-tab {
  &__section {
    margin-top: 32px;
    @include for-839 {
      margin-top: 0;
    }
  }

  &__title {
    font-weight: bold;
    line-height: 20px;
    letter-spacing: -0.05em;
    text-transform: uppercase;
    color: #F26D6B;

    @include for-839{
      padding-bottom:16px;
    }
  }

  &__main-text {
    font-size: 16px;
    line-height: 24px;
    font-family: RegularFont, sans-serif;
    font-weight: normal;
    color: #FFF;
    margin-top:16px;

    @include for-839 {
      margin-top: 0;
      padding-bottom: 18px;
    }

    &--tags {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;

      span {
        min-width: 51px;
        min-height: 28px;

        margin: 8px 12px 8px 0;
        padding: 2px 8px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;

        background: #404040;
        border-radius: 2px;
      }
    }

    &.icon-list {
      display: flex;
    }

    &--iconic {
      display: flex;
      align-items: center;
      margin-right: 30px;

      span {
        margin-left:16px;
      }
    }

    p {
      margin:0;

      &.margined {
        margin-bottom:16px;
      }
    }

    .topic {
      margin-top: 24px;
      &-title {
        margin-bottom: 16px;
        font-weight: bold;
      }
      &-text {

      }
    }
  }
}
.ant-collapse-content-inactive.ant-collapse-content-hidden {
  display: none !important;
}

.custom-collapse-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .ant-collapse-header {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    padding-bottom: 13px;
    color: #404040;
    border-bottom: 1px solid #404040;
    margin-bottom: 15px;
    outline: none;

    @include for-839 {
      font-size: 14px;
    }

    .ant-collapse-arrow {
      margin-left:8px;
      font-size: 15px;
      display: flex;
      align-items: center;
      margin-top: 4px;
    }
  }

  .ant-collapse-content {
    zoom: 1;
    position: relative;
    z-index: 10000;
  }


  &.delivery {
    .ant-collapse-content {
      &.ant-collapse-content-active {
        height: auto !important;
      }
    }
  }
}

.ant-collapse-item-active {
  .ant-collapse-header {
    color: #F26D6B;
    border-bottom: 1px solid #F26D6B;
  }
}

.ant-tooltip-open {
  transition-delay: 0s !important;
}
