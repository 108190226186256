@import "src/styles/breakpoints";

.content {
  @include for-839 {
    display: block;
  }
}

.content-career > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.career {
  display: flex;
  text-align: start;
  width: 1116px;
  justify-content: center;
  padding-top:50px;

  @include for-1439 {
    width: 928px;
  }

  @include for-1023 {
    width: 750px;
  }

  @include for-839 {
    width: 100%;
    padding: 0;
    flex-direction: column;
    background-color: #0E0D0D;
  }

  .info-block {
    color:#fff;
    width: 100%;
    box-sizing: border-box;
    flex-basis:46%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 88px;

    @include for-1439 {
      flex-basis:50%;
      padding-right: 138px;
    }

    @include for-1439 {
      padding-right: 44px;
    }

    @include for-840 {
      padding-right: 24px;
    }

    @include for-839 {
      padding: 36px 16px 33px;
      align-items: center;
    }

    &-content {
      max-width: 546px;
      margin-left: 0px;
      padding-left: 0px;

      @include for-1439 {
        padding-left: 48px;
      }

      @include for-1439 {
        padding-left: 0;
        max-width: 452px;
        margin-left: 0px;
      }

      @include for-840 {
        max-width: 360px;
        margin-left: 6px;
      }

      @include for-839 {
        width: 100%;
        max-width: none;
        margin-left: 0px;
        padding: 0px;
      }

      @include for-phone {
        margin-left:0;
        display: flex;
        flex-direction: column;

      }
    }

    .article {
      display: flex;
      flex-direction: column;

      &-icon {
        margin-left: 4px;
        text-align: left;
      }

      &-header {
        color: #F26D6B;
        text-align: left;
        text-transform: uppercase;
        font-size: 16px;
        margin-top: 5px;

        @include for-phone {
          margin-bottom:15px;
        }

        &::after {
          width:0px;
          height: 0px;
        }
      }
    }

    .vacancies {
      width: 100%;
      margin-top:50px;

      @include for-839 {
        margin-top: 0;
        min-width: 280px;
      }

      &-list {
        list-style: none;
        padding: 0;

        & > li {
          margin-bottom:15px;
          cursor:pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .about {
      width: 100%;

      @include for-840 {
        width:100%;
        max-width: none;
        min-width: 350px;
      }

      @include for-phone {
        margin-top: 0;
        min-width: 280px;
      }
    }
  }

  .infographic-block {
    flex-basis:50%;
    flex-grow: 1;
    color:#878787;
    padding-left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    @include for-1440 {
      padding-top: 50px;
      flex-basis:46%;
    }

    @include for-1439 {
      padding-top: 20px;
      flex-basis: 50%;
    }

    @include for-1023 {
      padding-top: 70px;
    }

    @include for-840 {
      flex-basis: 48%;
    }

    @include for-839 {
      padding-top: 32px;
    }

    .card-list {
      max-height: 600px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 24px;

      @include gt-1441 {
        padding-left: 0px;
      }

      @include for-1440 {
        padding-left: 14px;
      }

      @include for-1439 {
        padding-left: 0px;
      }

      @include for-980 {
        width: 100%;
        margin-right: 0px;
        padding-left: 0px;
      }

      @include for-839 {
        gap: 0;
        flex-direction: column;
        padding: 0px 0px 36px 0px;
      }

      .rec-item-wrapper {
        @include for-840 {
          justify-content: flex-start;
          width: 188px !important;
          margin-right: 16px;
        }
      }

      .rec-slider-container{
        @include for-839 {
          margin-left: 0px;
        }
      }

      .rec-pagination {
        @include for-980 {
          display: none;
        }


      }

      @include for-phone {
        width:100%;
        & > .rec-carousel-wrapper {
          margin-bottom: 45px;

          & > .rec-carousel {

            .rec-swipable {
              align-items: flex-start;
            }
          }

          & > .rec-pagination{

            & > .rec-dot {
              background-color: #404040;
              width:16px;
              height: 16px;
              padding:0;

              &_active {
                background-color: #fff;
                box-shadow:none;
              }
            }
          }
        }
      }

      &-item {
        background: #1D1B1B;
        width:284px;
        height: 284px;
        margin:14px 12px 24px;
        text-align: center;
        padding:8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        outline: none;
        overflow: hidden;

        @include for-1440 {
          margin:14px 0px 14px;
          width: 204px;
          height: 204px;
        }

        @include for-1023 {
          width:168px;
          height: 168px;
        }

        @include for-839 {
          width: auto;
          height: 178px;
          margin: 0px 0px 16px;
        }

        &__number {
          font-size: 48px;
          line-height: 110px;
          letter-spacing: -0.1em;
          color: #F26D6B;
          font-family: RegularFont, sans-serif;
          font-feature-settings: 'liga' off;

          &.no-spacing {
            letter-spacing: normal;
          }

          @include for-1440 {
            font-size: 48px;
            line-height: 90px;
          }

          @include for-839 {
            font-size: 64px;
            line-height: 97px;
          }
        }

        &__text {
          font-family: RegularFont, sans-serif;
          font-size: 20px;
          line-height: 25px;
          max-width: 180px;

          @include for-1440 {
            font-size: 16px;
            line-height: 20px;
          }

          @include for-839 {
            font-size: 14px;
            line-height: 21px;
            max-width: 168px;
          }
        }
      }

      &-col:first-child {
        align-self: center;
      }

      &-col.last {
        .card-list-item {
          margin: 14px 0px 24px 12px;
        }
      }
    }


  }
}
