$content-tablet-width: 840px;
$iphone-x-width: 375px;
$iphone-se-width: 320px;
$iphone-12-width: 390px;


@mixin for-phone {
  @media screen and (max-width: $iphone-12-width) {
    @content;
  }
}

@mixin for-desktop {
  @media screen and (min-width: $content-tablet-width) {
    @content;
  }
}

@mixin for-320 {
  @media screen and (max-width : 320px) {
    @content;
  }
}

@mixin for-375 {
  @media screen and (max-width : 375px) {
    @content;
  }
}

@mixin for-768 {
  @media screen and (max-width : 768px) {
    @content;
  }
}

@mixin for-839 {
  @media screen and (max-width : 839px) {
    @content;
  }
}

@mixin for-840 {
  @media screen and (max-width : 840px) {
    @content;
  }
}

@mixin for-980 {
  @media screen and (max-width : 980px) {
    @content;
  }
}

@mixin for-1023 {
  @media screen and (max-width : 1023px) {
    @content;
  }
}

@mixin for-1024 {
  @media screen and (max-width : 1024px) {
    @content;
  }
}

@mixin for-1340 {
  @media screen and (max-width : 1340px) {
    @content;
  }
}

@mixin for-1439 {
  @media screen and (max-width : 1439px) {
    @content;
  }
}

@mixin for-1440 {
  @media screen and (max-width : 1440px) {
    @content;
  }
}

@mixin for-more-1440 {
  @media screen and (min-width : 1440px) {
    @content;
  }
}

@mixin gt-1441 {
  @media screen and (min-width : 1440px) {
    @content;
  }
}

@mixin for-1919 {
  @media screen and (max-width : 1919px) {
    @content;
  }
}